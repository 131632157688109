import React, {useContext, useEffect, useState} from "react";
import {Props} from "./interface";
import styles from './TextLabel.module.css'
import {getImgPath, getSizes, removeMatchingKeys} from "../../../../helpers/functions";
import UIContext from "../../../../storage/UIContext";
import LanguageContext from "../../../../storage/LanguageContext";
import {getDataStorage} from "../../../../helpers/storage.helper";
import {useHttp} from "../../../../hooks";
import DynamicObject from "../../../../models/dynamic-object";
import {configWidgetApi} from "../../../../config/api.config";
import {Args} from "../../../../hooks/use-http/interfaces";
import FlowReferences from "../../../../flow-references";
import {AxiosError, AxiosResponse} from "axios/index";
import PostContext from "../../../../storage/PostContext";
import {Modal} from "../../../UI";
import MapperSelectField from "../../../MapperSelectField";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";

const TextLabel = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {config} = props

    const {validation, state} = useDataPayload<string>({
        ref,
        config: props.config,
        setConfig: props.setConfig,
        defaultValue: null
    })


    /************************************
     *            STATES
     * *********************************/
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [btnLoader, setBtnLoader] = useState<boolean>(false)
    const [currentConfigApi, setCurrentConfigApi] = useState<DynamicObject>({})
    const [tempFormData, setTempFormData] = useState<DynamicObject>({})

    /************************************
     *        INLINE STYLES
     * *********************************/

    const langCtx = useContext(LanguageContext)
    const storage = getDataStorage()
    const postCtx = useContext(PostContext)

    const rightAlignment = props.config.text?.align.toLowerCase() == 'right' && langCtx.language == 'en' && 'end'
    const isMobileOrDesktopStyle = {
        border: props.config?.border && `1px ${props.config?.border?.type.toLowerCase() == 'none' && props.config?.border?.color ? 'solid' : props.config?.border?.type} ${props.config?.border?.color}`,
        borderRadius: props.config?.border?.round ? props.config?.border?.type.toLowerCase() == 'solid' ? "20px" : "5px" : "0px",
    }

    let textStyle = {
        fontWeight: props.config.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.text?.style?.size),
        color: props.config.text.color,
        textAlign: props.config.text?.align,
        float: props.config.text?.align,
        marginTop: '2px',
        cursor: props?.config?.showWidget ? 'pointer' : 'default'
    } as React.CSSProperties

    const containerStyle = {
        "--text-font-weight": props.config.text?.style?.bold ? "bold" : "normal",
        "--text-font-size": getSizes(props.config.text?.style?.size),
        "--text-font-color": props.config.text.color,
        width: props.config?.fullWidth ? '100%' : '75%',
        border: isMobileOrDesktopStyle.border,
        borderRadius: isMobileOrDesktopStyle.borderRadius,
        backgroundColor: props.config?.background && props.config?.background?.color,
        padding: props.config?.text?.parse_as?.toLowerCase() === 'html' && '5px',
        marginTop: '20px'
    } as React.CSSProperties
    
    if(props.config?.text?.maxLines) {
        textStyle = {
            ...textStyle,
            textOverflow: "ellipsis",
            display: "-webkit-box",
            overflow: "hidden",
            lineHeight:  !isNaN(props.config?.text?.maxLines) ? `${0.7 * +props.config?.text?.maxLines}em` : "",
            WebkitLineClamp: props.config?.text?.maxLines,
            WebkitBoxOrient: "vertical",
        }
    }


    /************************************
     *           GET WIDGET
     * *********************************/
    const widgetApi = useHttp<DynamicObject>(configWidgetApi)


    const request = widgetApi.request

    /************************************
     *        PREPARE REQUEST
     * *********************************/
    widgetApi.request = (args?: Args) => {
        const params: DynamicObject = {
            id: props?.config?.showWidget?.identifier,
            type: 'add-post',
            workflowId: postCtx.data.workflow_id,
            draftId: postCtx.data.draft_id,
            stepId: postCtx.data.step?.identifier
        }

        //Linked Child
        // if (props.config?.parent?.length) {
        //     for (const field of props.config.parent) {
        //         params[field] = FlowReferences.get(field)?.state?.get() || postCtx.data.form[field]
        //     }
        // }

        return request({
            ...args,
            config: {
                ...args?.config,
                language: document.cookie.replace(/(?:(?:^|.*;\s*)lang\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                params
            }
        })
    }


    /*--------------------------------------------------------------
     *                         FUNCTIONS
     * ------------------------------------------------------------*/

    /************************************
     *         ACTIONS HANDLER
     * *********************************/
    const onSuccessWidgetApi = (response: AxiosResponse) => {

        setCurrentConfigApi(response.data)
        setIsOpen(true)
        setBtnLoader(false)
    }
    const onErrorWidgetApi = (response: AxiosError) => {
        setBtnLoader(false)
    }

    const onOpenHandler = () => {
        if (props?.config?.showWidget?.type == 'popup') {
            if (isOpen) {
                setIsOpen(false)
                setBtnLoader(false)

            } else {
                widgetApi.request({
                    callbacks: {
                        success: onSuccessWidgetApi,
                        error: onErrorWidgetApi
                    }
                })
            }
        } else {
            // bottomSheet Not Handled yet
        }


    }
    const Text = () => {
        if (props.config?.text?.parse_as?.toLowerCase() == 'html')
            return <div onClick={props?.config?.showWidget && onOpenHandler} style={textStyle} className={styles.text_overflow}
                        dangerouslySetInnerHTML={{__html: props.config?.text?.locale}}/>;
        else
            return <div onClick={props?.config?.showWidget && onOpenHandler} className={styles.text_overflow}
                        style={textStyle}>{props.config?.text?.locale}</div>
    }

    return (<>
            <div
                className={`${props.config.fullWidth ? containerStyle.width : `d-flex justify-content-${textStyle?.textAlign.toLowerCase() == 'right' && langCtx.language == 'en' ? rightAlignment : textStyle?.textAlign}`}`}>
                <div
                    style={containerStyle}
                    className={`${styles.container}`}>
                    <div className={`d-flex flex-column justify-content-center m-1`}>
                        <div
                            className={`col d-flex justify-content-${props.config.fullWidth && textStyle?.textAlign.toLowerCase() == 'right' && langCtx.language == 'en' ? rightAlignment : textStyle?.textAlign} ${styles.icon_list_container}`}>
                            {props.config.text.icon &&
                                <div style={{
                                    float: langCtx.language === 'en' ? 'left' : 'right',
                                    marginTop: props.config?.text?.parse_as?.toLowerCase() == 'html' && '2px'
                                }}><img style={{width: "22px"}}
                                        src={getImgPath(props.config.text.icon)}/></div>}
                            {Text()}
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && currentConfigApi &&
                <Modal styles={{
                    modal: {
                        height: "auto",
                        width: `${props.config?.showWidget?.width}vw` ?? "80vw",
                    }, content: {
                        height: "auto",
                        padding:"5px"
                    }
                }} setIsOpen={setIsOpen}>
                    <MapperSelectField
                        config={currentConfigApi}
                        setConfig={props.setConfig}
                        selectFieldLevels={props.levels}
                        tempFormData={tempFormData}
                        selectFieldConfig={config}
                        value={state.value} setConfigData={props.setConfigData}/>
                </Modal>}
        </>

    )
})

export default TextLabel