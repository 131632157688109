import React, {useContext, useEffect, useState} from "react";
import {Props} from "./interface";
import styles from './Section.module.css'
import {arrayIsEmpty, getImgPath, getSizes} from "../../../../helpers/functions";
import DynamicObject from "../../../../models/dynamic-object";
import SectionCacher from "../../../../models/section-cacher";
import {ROW_DIRECTION} from "../../../../constants/grid";
import GridLayout from "../../../../grid-layout";
import UIContext from "../../../../storage/UIContext";
import {getDataStorage} from "../../../../helpers/storage.helper";
import FlowReferences from "../../../../flow-references";
import PostContext from "../../../../storage/PostContext";

const Section = (props: Props): JSX.Element => {

    const {config} = props

    const storage = getDataStorage()
    const uiCtx = useContext(UIContext)
    const postCtx = useContext(PostContext);
    const [childAction, setChildAction] = useState('')

    const isMobileOrDesktopStyle = {
        border:props.config?.border && `1px ${props.config?.border?.type.toLowerCase()=='none' && props.config?.border?.color ? 'solid': props.config?.border?.type} ${props.config?.border?.color}`,
        borderRadius: props.config?.border?.round ? "15px" : "5px"
    }

    /************************************
     *           CSS STYLE
     * *********************************/
    const containerStyle = {
        // "--background-color": props.config?.background?.color,
        // "--border-color": props.config?.border?.color,
        backgroundColor: props.config?.background?.color ?? '',
        gridArea: props.config.identifier,
        // gridGap:"50px",
        borderRadius: isMobileOrDesktopStyle.borderRadius,
        border: isMobileOrDesktopStyle.border,

        boxShadow: props.config.background && props.config.border && '0px 2px 16px rgba(190, 196, 209, 0.4)',
        // gap:"50px",
        padding: storage.isMobile && postCtx.data.step.type!=="previewStep" ? "8px 16px" : !props.config.background && !props.config.border ? '0' : '12px 16px',
    } as React.CSSProperties

    /************************************
     *      INLINE TITLE STYLE
     * *********************************/
    const titleStyle = {
        fontWeight: config.title?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.title?.style?.size),
        color: config.title?.color,
        margin: "5px 0px 5px 0px"
    } as React.CSSProperties

    /************************************
     *     INLINE SUBTITLE STYLE
     * *********************************/
    const subTitleStyle = {
        fontWeight: config.subTitle?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.subTitle?.style?.size),
        color: config.subTitle?.color,
    } as React.CSSProperties


    const checkTypeOfSectionChild = (type: string) => {
        const targetChild = props.config.childs.filter(child => child.type.toLowerCase() == type.toLowerCase())
        if (targetChild?.length == 1) {
            return targetChild[0].identifier
        } else {
            return false
        }
    }


    FlowReferences.load(config.identifier, {
        config: {
            set: props.setConfig,
            get: () => config
        }
    })

    useEffect(() => {
        const result = checkTypeOfSectionChild("textLabelWithAction")
        if (result)
            setChildAction(result)
    }, [])

    const attributes: DynamicObject = {
        id: props.config.identifier,
        className: styles.container,
        style: containerStyle
    }
    if (childAction) {
        const callback = SectionCacher.getInstance().get(childAction)
        if (callback) {
            attributes.className = `${attributes.className} cursor__pointer`
            attributes.onClick = () => {
                callback()
            }
        }
    }
    const childContainerStyle = {} as React.CSSProperties

    if (props.config?.layout?.direction === ROW_DIRECTION) {
        const gridLayout = GridLayout.createInstance()
        props.config.childs.map(function (component) {
            const max = Math.max(...config.layout.columns)
            gridLayout.load(component.identifier, component?.layout?.columns, max)
        })
        childContainerStyle.display = "grid"
        childContainerStyle.gridGap = "20px"
        childContainerStyle['grid-template-areas'] = `'${gridLayout.draw()}'`
        // childContainerStyle['grid-template-columns'] =`repeat(${gridLayout.gridCols.length}, 1fr)`
        childContainerStyle.gridTemplateColumns =gridLayout.getGridColumns()
        // childContainerStyle['grid-template-columns'] = '2fr 1fr'
    } else {
        childContainerStyle.display = "flex"
        childContainerStyle.flexDirection = "column"
        childContainerStyle.gridGap = storage.isMobile ? '5px' : props.config.identifier.toLowerCase().includes('mega') ? '24px' : '16px'

    }

    return ((!arrayIsEmpty(config.childs) || !props.config?.hide) &&
        <div className={styles.container} style={containerStyle}>
            {config?.title && <div style={titleStyle}>{config.title?.locale}</div>}
            {config?.subTitle && <div style={{display: "flex", gap: "5px"}}>
                <div className={`${styles.flexContainer}`}>
                    <div>{config?.subTitle.icon &&
                        <img style={{width: "20px"}} src={getImgPath(config?.subTitle.icon)}/>}</div>
                    <div className={`mb-3`}><span style={subTitleStyle}>{config?.subTitle?.locale}</span></div>
                </div>
            </div>}
            <div style={childContainerStyle}>
                {props.children}
            </div>
        </div>
    )
}

export default Section