import ArachnaApi from './requests/arachna-api'
import {AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";
import {AddPostFilterResponse} from "../interceptors";
import MediasCacher from "../models/medias-cacher";
import Cookies from "js-cookie";
import {isIFrame} from "../helpers/functions";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const isBehalfLogIn=Cookies.get('EmployeeToken') && isIFrame() && Cookies.get('audience') == 'infinity';
    const request = ArachnaApi.getInstance().request
    request.interceptors.response.use(AddPostFilterResponse().success, AddPostFilterResponse().error)

    const requestMap = new Map([
        ['post', new Map([
            ['method', request.post],
            ['endpoint', `vertical/forms/v1/add-post/${isBehalfLogIn?'on-behalf':'normal'}/${config?.data.currentStep}/${config?.data.workflowId}`]
        ])],
        ['init-cv', new Map([
            ['method', request.get],
            ['endpoint', `vertical/forms/v1/cv/normal/1`]
        ])],
        ['cv', new Map([
            ['method', request.post],
            ['endpoint', `vertical/forms/v1/cv/normal/${config?.data.currentStep}/${config?.data.workflowId}`]
        ])],
        ['survey', new Map([
            ['method', request.post],
            ['endpoint', `vertical/forms/v1/survey/normal/${config?.data.currentStep}/${config?.data.workflowId}`]
        ])],
        ['requestFeature', new Map([
            ['method', request.post],
            ['endpoint', `vertical/forms/v1/requestFeature/normal/${config?.data.currentStep}/${config?.data.workflowId}`]
        ])],
        ['delete', new Map([
            ['method', request.post],
            ['endpoint', `vertical/forms/v1/deleteReason/deeplink/${config?.data.currentStep}/${config?.data.workflowId}`]
        ])]
    ])


    const medias = {}
    for (const mediaIdentifier in MediasCacher.getInstance().all()) {
        medias[mediaIdentifier] = Object.values(MediasCacher.getInstance().get(mediaIdentifier)?.medias || []).length
    }

    config.data = {
        ...config.data,
        ...medias
    }

console.log(config?.flow_type,"here in Api")
    const requestItem = requestMap.get(config?.flow_type)
    const method = requestItem?.get('method')
    const endpoint = requestItem?.get('endpoint')
    console.log(requestItem,config?.flow_type,"here in api")
    return method(`${process.env.REACT_APP_ARACHNA_API_URL}/${endpoint}`, {
        draftId: config?.data.draftId,
        time_spent_ms: config?.data.time_spent_ms,
        ...config?.data,
    })
}