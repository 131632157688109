import React, {useEffect} from "react";
import {Props} from './interfaces';
import styles from "./TextField.module.css"
import {TextField as TextFieldMUI} from "@mui/material";
import {getFieldType, getInputType, getSizes, handleTextFieldInput, numberCommaFormatter} from "../../../../helpers/functions";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";

const TextField = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config,
        autoSaveDelay: 3000
    })
    const [displayedValue, setDisplayedValue] = React.useState<string>('')


    const type = getFieldType(props.config.format.toLowerCase())
    const inputType = getInputType(props.config.format.toLowerCase())




    useEffect(() => {
        if(props.config?.thousands_separator){
            setDisplayedValue(numberCommaFormatter(state?.value))
        } else {
            setDisplayedValue(state?.value || '')
        }
    }, [props.config?.thousands_separator])
    

    const handleInput = (e) => {
        const stateSetter = (value) => {
            state.set(value)
        }
        handleTextFieldInput({
             event: e,
             fieldFormat: props.config.format,
             setDisplayedValue,
             stateSetter,
             thousandsSeparator: props.config?.thousands_separator,
             maxNumberOfChar: props.config?.limit,
             maxNumberAfterAllowed: props?.config?.decimal_digits
            })
    };



    useEffect(() => {
        validation.clear()
    }, [state.value])

    const attributes = {
        id: "outlined",
        fullWidth: true,
        maxRows: 4,
        placeholder: props.config.placeholder.locale,
        InputLabelProps: {
            style: {
                color: props.config.placeholder.color,
                fontWeight: props.config.placeholder.style?.bold ? 'bold' : 'normal',
                fontSize: getSizes(props.config.placeholder.style?.size),
            }
        },
        InputProps: {
            style: {
                color: props.config.text.color,
                fontWeight: props.config.text?.style?.bold ? 'bold' : 'normal',
                fontSize: window.innerWidth <= 768 ? '18px' : getSizes(props.config.text?.style?.size),
            },
            readOnly: !props.config.editable,
        }

    }

    const unitStyle = {
        color: props.config?.unit?.color?.normal,
        fontWeight: props.config?.unit?.style?.bold ? 'bold' : 'normal',
        fontSize: getSizes(props.config?.unit?.style?.size),
    }

    document.addEventListener("wheel", function (event) {
        if (type === "number")
            document.getElementById("outlined")?.blur()

    });


    return (

        <div id={props.config.identifier}  className={`${styles.container} `}>
                <TextFieldMUI {...attributes}
                              autoComplete={"off"}
                              sx={{'.MuiOutlinedInput-notchedOutline': {borderRadius: "8px", borderColor: "#E6E3E3"}}}
                              onChange={handleInput}
                              value={displayedValue}
                              className={`${styles.text_locale_ar}`}
                              variant={'outlined'}
                              inputMode={inputType}
                              error={!!validation.error}
                              onPaste={(event) => {
                                  if (type != 'text') {
                                      event.preventDefault()
                                  }
                              }}
                              onCopy={(event) => {
                                  if (type != 'text') {
                                      event.preventDefault()
                                  }
                              }}
                              onCut={(event) => {

                                  if (type != 'text') {
                                      event.preventDefault()
                                  }
                              }}
                              autoCorrect={"off"}
                              spellCheck={"false"}
                              onKeyPress={handleInput}
                              InputProps={{
                                endAdornment: <p className="px-2" style={unitStyle}>{props.config?.unit?.locale}</p>,
                              }}

                />

            <ValidationError validation={validation}/>

        </div>

    )
})

export default TextField