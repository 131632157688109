import React, {useContext, useState} from "react";
import {Props, RefDataType} from './interfaces';
import styles from './Section.module.css'
import {arrayIsEmpty, getImgPath, getSizes, setSessionByKey} from "../../../../helpers/functions";
import UIContext from "../../../../storage/UIContext";
import {ROW_DIRECTION} from "../../../../constants/grid";
import GridLayout from "../../../../grid-layout";
import {getDataStorage} from "../../../../helpers/storage.helper";


import MapperFlow from "../../../MapperFlow";
import PostContext from "../../../../storage/PostContext";
import {Button as MuiButton} from "@mui/material";
import {AiOutlinePlusCircle} from "react-icons/ai";
import LanguageContext from "../../../../storage/LanguageContext";
import {useTranslation} from "react-i18next";

const Section = React.forwardRef<RefDataType, Props>((props, ref) => {

    const uiCtx = useContext(UIContext)
    const [showMore, setShowMore] = useState(false)
    const postCtx = useContext(PostContext)
    const storage = getDataStorage()
    const langCtx = useContext(LanguageContext)
    const {t} = useTranslation();
    const isMobileOrDesktopStyle = {
        border:props.config?.border && `1px ${props.config?.border?.type.toLowerCase()=='none' && props.config?.border?.color ? 'solid': props.config?.border?.type} ${props.config?.border?.color}`,
        borderRadius: props.config?.border?.round ? "8px":"5px",
    }


    const {config} = props

    {/*TITLE STYLING */
    }

    const containerStyle = {
        backgroundColor: props.config?.background?.color ?? '',
        borderColor: props.config?.border?.color ?? '',
        gridArea: props.config.identifier,
        borderRadius: isMobileOrDesktopStyle.borderRadius,
        border: isMobileOrDesktopStyle.border,
        boxShadow: props.config.background && props.config.border && '0px 2px 16px rgba(190, 196, 209, 0.4)',

        padding: storage.isMobile ? "8px 16px" : !props.config.background && !props.config.border ? '0' : '12px 16px',

    } as React.CSSProperties
    const titleStyle = {
        fontWeight: config.title?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.title?.style?.size),
        color: config.title?.color,
        margin: "5px 0px 5px 0px"
    } as React.CSSProperties

    const subTitleStyle = {
        fontWeight: config.subTitle?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.subTitle?.style?.size),
        color: config.subTitle?.color,
    } as React.CSSProperties

    const childContainerStyle = {} as React.CSSProperties

    // if (props.config?.layout == null) {
    //     setSessionByKey('flow-type','job')
    // }

    if (props.config?.layout?.direction === ROW_DIRECTION) {
        const gridLayout = GridLayout.createInstance()
        props.config.childs.map(function (component) {
            const max = Math.max(...config.layout.columns)
            gridLayout.load(component.identifier, component?.layout?.columns, max)
        })
        childContainerStyle.display = "grid"
        childContainerStyle.gridGap = (gridLayout.getGridColumns().includes("3.5")) ? "0px" : "20px"
        childContainerStyle.alignItems = "flex-start"
        childContainerStyle.gridTemplateAreas = `'${gridLayout.draw()}'`
        childContainerStyle.gridTemplateColumns =gridLayout.getGridColumns()
        childContainerStyle.marginTop="10px"

    } else {
        childContainerStyle.display = "flex"
        childContainerStyle.flexDirection = "column"
        childContainerStyle.gridGap = storage.isMobile ? '5px' : props.config.identifier.toLowerCase().includes('mega') ? '24px' : '16px'

    }

    const onClickHandler = () => {
        setShowMore(true)
    }


    return (
        !showMore ?
            (!arrayIsEmpty(config.childs) || !props.config?.hide) &&
            <div className={styles.container} style={containerStyle}>
                <div style={{margin: config?.title || config?.subTitle ?"10px 0px":""}}>
                    {config?.title && <div style={{display: "flex", gap: "5px"}}>
                        <div className={`${styles.flexContainer}`}>
                            <div>{config?.title.icon &&
                                <img style={{width: "30px",height:"30px"}} src={getImgPath(config?.title.icon)}/>}</div>
                            <div className={`mb-1 d-flex flex-column justify-content-center`}><span
                                style={titleStyle}>{config?.title?.locale}</span></div>
                        </div>
                    </div>}
                    {config?.subTitle && <div style={{display: "flex", gap: "5px"}}>
                        <div className={`${styles.flexContainer}`}>
                            <div>{config?.subTitle.icon &&
                                <img style={{width: "20px"}} src={getImgPath(config?.subTitle.icon)}/>}</div>
                            <div className={`mb-1 d-flex flex-column justify-content-center`}><span
                                style={subTitleStyle}>{config?.subTitle?.locale}</span></div>
                        </div>
                    </div>}
                </div>

                <div style={childContainerStyle}>
                    {props.children}
                    {props.showMore && <div className={`d-flex justify-content-center align-items-center`}><MuiButton
                        className={`${styles.show_more_button}`}
                        endIcon={langCtx.language == 'ar' &&
                            <AiOutlinePlusCircle className={`${styles.icon_dimensions}`}/>}
                        startIcon={
                            langCtx.language == 'en' && <AiOutlinePlusCircle
                                className={`${styles.icon_dimensions}`}/>}
                        onClick={onClickHandler}
                    >
                        <div
                            className={`${styles.more_text} ${langCtx.language == 'ar' ? styles.margin_left : ''}`}>{t('showMore')}</div>
                    </MuiButton></div>}            </div>
            </div> :
            <MapperFlow config={props.config} nextApi={props.nextApi} Previous={props.Previous} showMore={false}/>
    )
})
export default Section