import React, {useContext} from "react";
import {Props} from "./interface";
import {getDataStorage} from "../../../../helpers/storage.helper";
import {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import ListCellOptionsMobile from "./ListCellOptionsMobile/ListCellOptionsMobile";
import ListCellOptionsDesktop from "./ListCellOptionsDesktop/ListCellOptionsDesktop";
import {useDataPayload} from "../../../../hooks";
import NotAutoSaveState from "../../../../not-auto-save-state";
import FlowReferences from "../../../../flow-references";
import postContext from "../../../../storage/PostContext";
import PostContext from "../../../../storage/PostContext";


const ListCellOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
        const {config, setConfig} = props

        /*-------------------------------------
         *              HOOKS
         * ----------------------------------*/
        const postCtx = useContext(PostContext);
        /************************************
         *   SET & GET VALIDATION AND STATES
         * *********************************/
        const {state, validation} = useDataPayload<string | number>({
            ref,
            config: props.config,
            setConfig: props.setConfig,
            defaultValue: ""
        })


        const rest = (config) => {
            if (!config?.linked?.length) {
                return
            }

            config.linked.map(identifier => {
                NotAutoSaveState.set(identifier)
                if (FlowReferences.get(identifier)?.state)
                    FlowReferences.get(identifier).state.clear()

                if (FlowReferences.actions[identifier]?.onClear)
                    FlowReferences.actions[identifier].onClear()

                rest(FlowReferences.get(identifier)?.config?.get())
            })
        }


        state.set = (value) => {
            state.set(value, () => {
                rest(config)
            })
        }

        const onChangeValues = (id, values) => {
            if (props.onChangeValues) {
                props.onChangeValues(id, values, () => rest(config))
            }
        }

        const storage = getDataStorage()


        return (
            <>
                {storage.isMobile ? <ListCellOptionsMobile config={props.config} setConfigData={props.setConfigData}
                                                           setConfig={setConfig}
                                                           state={state}
                                                           validation={validation}
                                                           selectFieldLevels={props.selectFieldLevels}
                                                           selectFieldConfig={props.selectFieldConfig}
                                                           tempFormData={props.tempFormData}
                                                           nextApi={props.nextApi}
                                                           {...props}
                                                           onChangeValues={onChangeValues}
                                                           ref={ref}></ListCellOptionsMobile> :
                    <ListCellOptionsDesktop config={props.config} setConfigData={props.setConfigData}
                                            state={state}
                                            validation={validation}
                                            setConfig={setConfig}
                                            selectFieldLevels={props.selectFieldLevels}
                                            selectFieldConfig={props.selectFieldConfig} tempFormData={props.tempFormData}
                                            nextApi={props.nextApi} ref={ref}
                                            {...props}
                                            onChangeValues={onChangeValues}
                    ></ListCellOptionsDesktop>
                }

            </>
        )
    }
)
export default ListCellOptions