import {FaAngleLeft, FaAngleRight, FaPhone, FaPhoneAlt, FaTimes} from "react-icons/fa";
import {IoCloseSharp} from "react-icons/io5";

import styles from './Navbar.module.css'
import {OpenSooqLogo} from '../../../assets/images'
import {ShobiddakLogo} from '../../../assets/images'
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import PostContext from "../../../storage/PostContext";
import UIContext from "../../../storage/UIContext";
import {useNavigate} from "react-router-dom";
import {useHttp} from "../../../hooks";
import DynamicObject from "../../../models/dynamic-object";
import previousApi from "../../../api/previous.api";
import {Args} from "../../../hooks/use-http/interfaces";
import {AxiosResponse} from "axios";
import {MediaMapper} from "../../../helpers/media.functions";
import {getFinalPayload, getSizes, getUrlBasedOnCountry, isIFrame} from "../../../helpers/functions";
import Previous from "../../UI/Previous/Previous";
import ConfirmationModel from "../../UI/ConfirmationModal/ConfirmationModel";
import {getDataStorage} from "../../../helpers/storage.helper";
import { defaultOptions, setCookie } from "../../../logging/helpers/commonCookiesFunctions";
import Cookies from "js-cookie";
import {logScreenLoad} from "../../../logging/helpers/commonLoggingFunctions";
import {CATEGORY_PAGE, DELETE, DELETE_REASON, DELETE_REASON2, FAILED_PAGE, PENDING_PAGE, PENDING_PAYMENT_PAGE, SUCCESS_PAGE ,SURVEY} from "../../../constants/Status";
import MediasCacher from "../../../models/medias-cacher";
import useVasStore from "../../../storage/VasContext";
import {FUNNEL_EVENTS, pushEventsToDataLayer, trackEvent} from "../../../helpers/Gtm";

const Navbar = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const [deleteMsg, setDeleteMsg] = useState({title: "", subTitle: "", target: ""})
    const [showDialogMessage, setShowDialogMessage] = useState<boolean>(true);
    const {isPromoteFlow, setTrigger, enableBackButton, promoteFlowCloseText, promoteFlowTitle} =  useVasStore();
    const navigate = useNavigate()

    const {t} = useTranslation();
    const postCtx = useContext(PostContext)
    const uiCtx = useContext(UIContext)

    const previousHttp = useHttp<DynamicObject>({
        callback: previousApi,
        initialData: {}
    })
    const request = previousHttp.request

    const storage = getDataStorage();

    previousHttp.request = function (args?: Args) {

        uiCtx.setLoading(true)
        const data: DynamicObject = {
            draftId: postCtx.data.draft_id,
            workflowId: postCtx.data.previous?.workflow_id,
            currentStep: postCtx?.data?.previous?.step_identifier,
            flow_type: postCtx.data.flow_type == 'cv' ? 'cv' : postCtx.data.flow_type == 'survey' ? 'survey' : postCtx.data.flow_type == "delete" ? "delete" : 'add-post'
        }

        const onSuccess = (response: AxiosResponse) => {
            postCtx.updateData({
                workflow_id: response.data.workflow_id,
                draft_id: response.data.draft.id,
                step: response.data.step,
                start_time: Date.now(),
                previous: response.data.previous,
                media: MediaMapper(response.data.media),
            })

            if (response.data.draft.id !== postCtx.data.draft_id){
                MediasCacher.getInstance().clear()
            }

            const mediaResult = MediaMapper(response.data.media)

            if (response.data.step?.identifier.includes('CV')) {

                postCtx.updateData({
                    form: {
                        ...data,
                        ...response.data.draft.payload
                    },
                    media: mediaResult
                })
            } else {
                const finalPayload = getFinalPayload(response.data.draft.payload)
                postCtx.updateData({
                    form: {
                        ...data,
                        ...finalPayload
                    },
                    media: mediaResult
                })
            }

            uiCtx.setLoading(false)
        }

        request({
            ...args,
            config: {
                ...args?.config,
                data: {
                    ...args?.config?.data,
                    ...data
                },
                type: data.flow_type,
                lang: "en"
            },
            callbacks: {
                ...args?.callbacks,
                success: onSuccess
            },

        })
    }

    function callPreviousApi() {
        //logging
        logScreenLoad();
        //
        previousHttp.request(
            {
                config: {
                    data: {
                        draftId: postCtx.data.draft_id,
                        workflowId: postCtx.data.previous?.workflow_id,
                        currentStep: postCtx?.data?.previous?.step_identifier,
                        flow_type: postCtx.data.flow_type === 'cv' && sessionStorage.getItem('flow-type') != 'job' ? 'post' : postCtx.data.flow_type,
                    },
                },
            }
        )
    }

    const onPreviousHandler = () => {
        callPreviousApi();
    }


    const backEventHandler = () => {

        if (postCtx.data.previous) {
            callPreviousApi();
        }
    }


    const addBackEventListener = () => {

        if (isIFrame()) {
            const listener = (e) => {
                if (e.data?.action === "on-previous") {
                    backEventHandler()
                }
            }
            window.addEventListener('message', listener, false)
            return () => {
                window.removeEventListener('message', listener, false)
            }
        }

        window.addEventListener("on-previous", backEventHandler)
        return () => {
            window.removeEventListener("on-previous", backEventHandler)
        }
    }


    // useEffect(addBackEventListener, [postCtx.data]);


    window.onpopstate = (e) => {
        e.preventDefault()
        // window.dispatchEvent(new CustomEvent("on-previous"))
    }


    useEffect(() => {
        if (width <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width]);


    useEffect(() => {
        window.addEventListener('message', function (event) {
            if (event.data && typeof event.data == "string") {
                if (event.data === 'without_confirm_dialog') {
                    setShowDialogMessage(false);
                }
            }
        });
    }, [])

    if (window.location !== window.parent.location) {
        return <></>
    }


    const onCloseHandler = () => {
        const pathName: string = window.location.pathname.toLowerCase()
        const pathNameAsUri = pathName.split("/");
        const uriCheckDisplayConfirmationArray = [SUCCESS_PAGE, FAILED_PAGE, PENDING_PAYMENT_PAGE, PENDING_PAGE, CATEGORY_PAGE, SURVEY, DELETE_REASON, DELETE_REASON2, DELETE]
        if ((uriCheckDisplayConfirmationArray.some((str) => pathNameAsUri.includes(str)) || !showDialogMessage) || (isPromoteFlow && !promoteFlowCloseText)) {
            onConfirmCloseHandler()

        } else setIsOpen(true)
    }

    const onConfirmCloseHandler = () => {
        const storage = getDataStorage()
        let URL = Cookies.get('addPostRef')
        const domain = process.env.REACT_APP_JO_SOOQ_URL.replace('https://XX', '');
        document.cookie = `addPostRef=;expires=;max-age=0;domain=${domain};path=/`;
        if (!URL || (URL && URL === '')) {
            URL = getUrlBasedOnCountry(storage.country)
        } else if (URL && getOriginDomain() === process.env.REACT_APP_SHOBIDAK_DOMAIN) {
            URL = getUrlBasedOnCountry(storage.country)
        }
        if(sessionStorage.getItem('loggingFlow') && (sessionStorage.getItem('loggingFlow') === 'draft' || sessionStorage.getItem('loggingFlow') === 'republish')){
            trackEvent(FUNNEL_EVENTS.DROP);
            pushEventsToDataLayer();
        }else if(postCtx.data.flow_type === 'add' || postCtx.data.flow_type === 'post'){
            trackEvent(FUNNEL_EVENTS.DROP);
            pushEventsToDataLayer();
        }

        // Polling function to check when the eventQueue is removed from sessionStorage
        setTimeout(() => {
            const checkEventQueueCleared = setInterval(() => {
                if (!sessionStorage.getItem('eventQueue')) {  // Check if eventQueue is removed
                    clearInterval(checkEventQueueCleared);    // Stop the interval
                    sessionStorage.removeItem("flow-type")
                    window.location.replace(URL);             // Redirect after eventQueue is cleared
                } else if (sessionStorage.getItem("flow-type") === "delete") {
                    sessionStorage.removeItem("flow-type")
                    sessionStorage.removeItem("eventQueue")
                }
            }, 100);
        },300)
    };

    const onConfirmDeleteHandler = () => {
        sessionStorage.removeItem("flow_type")
        window.location.href = deleteMsg?.target || null
    }

    const getOriginDomain = () => {
        var hostname = window.location.hostname; // Get the hostname from the current URL
        var parts = hostname.split('.'); // Split the hostname by periods
        if (parts.length > 2) { // Check if there is a subdomain
            hostname = parts.slice(1).join('.'); // Remove the subdomain
        }
        return hostname;
    }


    const getSiteLogo = () => {
        let logo = OpenSooqLogo;
        if (getOriginDomain() === process.env.REACT_APP_SHOBIDAK_DOMAIN) {
            logo = ShobiddakLogo;
        }
        return logo
    }

    return (<>
            <header className={`${styles.container} ${postCtx?.data?.flow_type === "reviews" && isMobile && 'p-2'}`}>
                <div className={`${styles.items}`}>
                    {((!postCtx?.data?.previous && !isPromoteFlow) || (isPromoteFlow && !enableBackButton)) && !isMobile && <div></div>}
                    {((!postCtx?.data?.previous && !isPromoteFlow) || (isPromoteFlow && !enableBackButton)) && isMobile && postCtx?.data?.flow_type !== "reviews" && (
                        <div className={styles.item}>
                            <img className={styles.sideMargin} src={getSiteLogo()} alt="Opensooq" />
                        </div>
                    )}
                    {postCtx?.data?.previous && !isPromoteFlow &&
                        <Previous onClickPreviousHandler={onPreviousHandler} withTitle={storage.isMobile}/>
                    }
                    {isPromoteFlow && enableBackButton &&
                        <Previous onClickPreviousHandler={()=>setTrigger(true)} withTitle={storage.isMobile}/>
                    }
                    {!isMobile && 
                        <div style={{position: "absolute", top: "15px", left: "45%"}} className={styles.item}>
                            <img src={getSiteLogo()} alt="Opensooq"/>
                        </div>
                    }
                    <div className={styles.phoneCloseIcon_container}>
                        {isMobile && postCtx?.data?.step?.extraBtns?.contactUs && <>
                            <div className={`${styles.item} ${styles.phone}`}>
                                <a href={`https://opensooq.com/site/dl?requestUri=${storage.country}/contactUs`}>
                                    <FaPhoneAlt style={{
                                        color: "#0179FF"
                                        , marginTop: "2px"
                                    }} className={styles.icon}/></a>
                            </div>
                        </>}
                        {postCtx?.data?.step?.extraBtns?.dynamic && (postCtx?.data?.step?.extraBtns?.dynamic || [])?.map((mapIcon, index) => (
                            <div className={`${styles.item} ${styles.phone}`} key={index}>
                            <a 
                            href={`${process.env.REACT_APP_JO_SOOQ_URL.replace("XX.", "").replace("xx.","")}/site/dl?requestUri=${mapIcon?.deeplink?.replace("XX", storage.country).replace("xx",storage.country)}`}
                            onClick={(e) => {
                                if(mapIcon?.confirmation) {
                                    e.preventDefault()
                                    setIsDeleteOpen(true)
                                    setDeleteMsg({
                                        title: mapIcon?.confirmation?.title || "",
                                        subTitle: mapIcon?.confirmation?.subTitle || "",
                                        target: `${process.env.REACT_APP_JO_SOOQ_URL.replace("XX.", "").replace("xx.","")}/site/dl?requestUri=${mapIcon?.deeplink?.replace("XX", storage.country).replace("xx",storage.country)}`
                                    })
                                } else return
                            }}
                            >
                                <img 
                                style={{
                                    color: "#0179FF",
                                    marginTop: "2px",
                                    height: "25px",
                                    width: "25px"
                                }} 
                                className={styles.icon}
                                alt={"mappedIcon"}
                                src={mapIcon?.icon?.replace("{size}", "xxh").replace("{platform}", "android")}
                                /></a>
                        </div>
                        ))

                        }
                        <div>
                            <IoCloseSharp style={{ height: "25px", width: "25px", marginTop: "3px", cursor: "pointer", marginRight: "10px", marginLeft: "10px" }}
                                           onClick={onCloseHandler} className={styles.icon}/>
                            {isMobile && postCtx?.data?.flow_type === "reviews" && (
                                <h2 style={{ margin: "0 10px" }}>
                                    {postCtx?.data?.step?.title?.locale}
                                </h2>
                            )}
                        </div>
                    </div>
                </div>
            </header>

            {
                isOpen && showDialogMessage &&
                <ConfirmationModel acceptAction={onConfirmCloseHandler}
                                   title={(isPromoteFlow && promoteFlowTitle) ? promoteFlowTitle : postCtx.data.flow_type === 'reviews' ? t('confirmationReviewTitle') : t('confirmationTitle')}
                                   message={(isPromoteFlow && promoteFlowCloseText) ? promoteFlowCloseText : postCtx.data.flow_type === 'reviews' ? t('confirmationReviewMessage') : t('confirmationMessage')}
                                   setIsOpen={setIsOpen}/>
            }
            {
                isDeleteOpen &&
                <ConfirmationModel acceptAction={onConfirmDeleteHandler}
                                   title={deleteMsg?.title ?? ""}
                                   message={deleteMsg?.subTitle ?? ""}
                                   setIsOpen={setIsDeleteOpen}/>
            }
        </>
    )
}

export default Navbar