import React, {useContext, useEffect} from "react";
import {useHttp} from "../../../hooks";
import DynamicObject from "../../../models/dynamic-object";
import {AxiosResponse} from "axios";
import {getFinalPayload} from "../../../helpers/functions";
import PostContext from "../../../storage/PostContext";
import MapperFlow from "../../MapperFlow";
import {Args} from "../../../hooks/use-http/interfaces";
import {ComponentLoader} from "../../UI";
import {useTranslation} from "react-i18next";
import {MdEditOff} from "react-icons/md";
import UIContext from "../../../storage/UIContext";
import {MediaMapper} from "../../../helpers/media.functions";
import LanguageContext from "../../../storage/LanguageContext";
import {configEditPostApi, configJobApplyApi, configNextApi} from '../../../config/api.config'
import MediasCacher from "../../../models/medias-cacher";
import FlowReferences from "../../../flow-references";


const EditPost = (): JSX.Element => {

    /************************************
     *              CONSTANT
     * *********************************/
    /*CONTEXT*/
    const postCtx = useContext(PostContext)
    const languageCtx = useContext(LanguageContext)
    const uiCtx = useContext(UIContext)
    /*TRANSLATE*/
    const {t} = useTranslation()
    sessionStorage.setItem("flow-type", "edit")
    /*URL PARAMS*/
    const urlParams = new URLSearchParams(window.location.search)
    const type = urlParams.get('type') == 'jobApply' ? 'jobApply' : 'normal'
    FlowReferences.updateFlow("update")
    /*---------------------------------------------------------------
    *                       HTTP REQUEST
    * --------------------------------------------------------------*/
    /************************************
     *       EDIT POST REQUEST API
     * *********************************/
    const editPostHttp = useHttp<DynamicObject>(configEditPostApi)
    const requestTakeAction = editPostHttp.request
    editPostHttp.request = (args) => {
        const success = (response: AxiosResponse) => {
            uiCtx.setLoading(false)
            const finalPayload = getFinalPayload(response.data.draft.payload)
            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...finalPayload,
                },
                step: {
                    ...postCtx.data.step,
                    ...response.data.step
                },
                draft_id: response.data.draft.id,
                workflow_id: response.data.workflow_id,

                media: MediaMapper(response.data.media),
                remaining_edit_counter: response.data.remaining_edit_counter,
                isEdit: true
            })

            if (response.data.draft.id !== postCtx.data.draft_id) {
                MediasCacher.getInstance().clear()
            }

            const mediaResult = MediaMapper(response.data.media)
            MediasCacher.getInstance().concat(mediaResult)
        }
        requestTakeAction({
            ...args,
            config: {
                post_id: urlParams.get('post_id'),
            },
            callbacks: {
                ...args?.callbacks,
                success: success,
            }
        })
    }

    /************************************
     *       JOB APPLY REQUEST API
     * *********************************/
    const jobApplyHttp = useHttp<DynamicObject>(configJobApplyApi)
    const requestTakeActionJobApply = jobApplyHttp.request
    jobApplyHttp.request = (args) => {
        const success = (response: AxiosResponse) => {
            uiCtx.setLoading(false)
            const finalPayload = getFinalPayload(response.data.draft.payload)
            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...finalPayload,
                },
                step: {
                    ...postCtx.data.step,
                    ...response.data.step
                },
                draft_id: response.data.draft.id,
                workflow_id: response.data.workflow_id,

                media: MediaMapper(response.data.media),

                isEdit: true
            })


        }
        requestTakeActionJobApply({
            ...args,
            config: {
                post_id: urlParams.get('post_id'),
            },
            callbacks: {
                ...args?.callbacks,
                success: success,
            }
        })
    }


    /************************************
     *       NEXT API REQUEST API
     * *********************************/
    const nextApi = useHttp<DynamicObject>(configNextApi)
    const request = nextApi.request
    nextApi.request = function (args?: Args) {
        const data = initializeData();
        request({
            ...args,
            config: {
                flow_type: postCtx.data.flow_type,
                ...args?.config,
                data: {
                    ...args?.config?.data,
                    ...data
                },
                language: languageCtx.language
            },
            callbacks: {
                ...args?.callbacks,
                before: () => {
                    uiCtx.setLoading(true)
                }
            }
        })
    }

    /*---------------------------------------------------------------
    *                       EFFECTS
    * --------------------------------------------------------------*/
    useEffect(() => {
        if (!postCtx.data.isEdit) {
            uiCtx.setLoading(true)
            if (type == 'normal')
                editPostHttp.request()
            else
                jobApplyHttp.request()
        }

    }, [languageCtx.language])

    /*---------------------------------------------------------------
    *                       FUNCTION
    * --------------------------------------------------------------*/
    function initializeData() {
        const data: DynamicObject = {
            draftId: postCtx.data.draft_id,
            time_spent_ms: Date.now() - postCtx.data.start_time,
            workflowId: postCtx.data.workflow_id,
            currentStep: postCtx?.data?.step?.identifier,
        }

        for (const key in postCtx.data.currentStepData) {
            data[key as keyof typeof data] = postCtx.data.form[key as keyof typeof postCtx.data.form]
        }
        return data;
    }

    return (
        <div>
            {uiCtx.isLoading && <ComponentLoader/>}
            {postCtx.data.remaining_edit_counter === 0 && <div style={{
                marginTop: "20%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <MdEditOff style={{height: "150px", width: "150px", color: "#2785c7"}}></MdEditOff>
                <p style={{
                    marginTop: "30px",
                    color: "#000",
                    fontSize: "18px",
                    fontWeight: "bold",
                    textAlign: "center"
                }}> {`${t('NumberOfPostEditLeft')} ${postCtx.data.remaining_edit_counter}`}</p>
                <p style={{
                    marginTop: "0px",
                    color: "#808184",
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "center"
                }}>{t('remainingEditCounterWarning')}
                </p>
            </div>}
            {postCtx.data?.step && !uiCtx.isLoading && postCtx.data.remaining_edit_counter !== 0 &&
                <MapperFlow config={postCtx.data.step}
                            nextApi={urlParams.get('type') == 'jobApply' ? jobApplyHttp : nextApi}/>}

        </div>
    )
}

export default EditPost